import {vm} from '@/main'
import moment from 'moment'

class PricePerPeriod {


    constructor( invoiceableItem, periodLastDate ) {
        this.invoiceableItem = invoiceableItem;
        this.startDate = this.invoiceableItem.start_date
        this.endDate = periodLastDate;
        this.pricePerMonth = invoiceableItem.price
        this.months = this.getMonths()
    }

    getMonths() {

        var dateStart = moment(this.startDate)
        var dateEnd = moment(this.endDate)
        var interim = dateStart.clone()
        var months = []

        while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
            months.push(interim.format('YYYY-MM'))
            interim.add(1,'month')
        }

        return months
    }

    handle(){

        if(this.months.length === 1) {

            var invoiceableDays = moment(this.endDate).diff(this.startDate, 'days') +1
            var quantity = this.getQuantity( invoiceableDays, moment(this.startDate).daysInMonth() )

            var pricePerPeriod = this.getPricePerPeriod( quantity )

            return this.getFormattedResponse( quantity, pricePerPeriod )
        }

        if(moment(this.startDate).format('D') === moment(this.endDate).format('D')) {

            let quantity = moment(this.endDate).diff(this.startDate, 'months')
            let pricePerPeriod = this.getPricePerPeriod( quantity )

            return this.getFormattedResponse( quantity, pricePerPeriod )
        }

        else return this.getMultipleMonthsResponse()
    }

    getQuantity( invoiceableDays, monthDaysCount ) {

        return invoiceableDays * ( 1 / monthDaysCount)
    }

    getPricePerPeriod( quantity ) {

        return quantity * this.pricePerMonth
    }

    getFormattedResponse( quantity, pricePerPeriod ) {

        var response = {}

        response['quantity'] = quantity
        response['pricePerMonth'] = this.pricePerMonth
        response['total'] = pricePerPeriod

        return response
    }

    getMultipleMonthsResponse() {

        var data = this.getAllMonthsData();

        var quantity = data.reduce(function(prev, cur) {
            return prev + (cur.quantity);
        }, 0);

        var total = data.reduce(function(prev, cur) {
            return prev + (cur.total);
        }, 0);

        return this.getFormattedResponse( quantity, total )
    }

    getAllMonthsData() {

        return this.months.map( month => {

            if( this.isFirstMonth(month) ) {
                return this.getFirstMonthResponse(month)
            }

            if( this.isFullMonth(month) ) {
                return this.getFullMonthResponse(month)
            }

            return this.getLastMonthResponse(month)

        })
    }

    isFirstMonth(month) {

        return month === moment(this.startDate).format('YYYY-MM')
    }

    isLastMonth(month) {

        return month === moment(this.endDate).format('YYYY-MM')
    }

    isFullMonth(month) {

        return !this.isFirstMonth(month) && !this.isLastMonth(month)
    }

    getFirstMonthResponse(month) {

        var lastDayOfMonth = moment(month).endOf('month').format('YYYY-MM-DD')
        var invoiceableDays = moment(lastDayOfMonth).diff(this.startDate, 'days')
        var quantity = this.getQuantity( invoiceableDays, moment(month).daysInMonth() )
        var pricePerPeriod = this.getPricePerPeriod( quantity )

        return this.getFormattedResponse( quantity, pricePerPeriod )
    }

    getFullMonthResponse(month) {

        var invoiceableDays = moment(month).daysInMonth()
        var quantity = this.getQuantity( invoiceableDays, moment(month).daysInMonth() )
        var pricePerPeriod = this.getPricePerPeriod( quantity )

        return this.getFormattedResponse( quantity, pricePerPeriod )
    }

    getLastMonthResponse(month) {

        var firstDayOfMonth = moment(month).startOf('month').format('YYYY-MM-DD')
        var invoiceableDays = moment(this.endDate).diff(firstDayOfMonth, 'days') +1
        var quantity = this.getQuantity( invoiceableDays, moment(month).daysInMonth() )
        var pricePerPeriod = this.getPricePerPeriod( quantity )

        return this.getFormattedResponse( quantity, pricePerPeriod )
    }

}

export default PricePerPeriod
